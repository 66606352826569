import React from 'react'
import PropTypes from 'prop-types'
import { ItemsWrapper } from './index.style'
import { getClassListFromTags } from '@utils'
import Item from '../Item'
import Grid from '@components/Grid'

const Items = ({ regions, initialDeployment, resetInitialDeployment }) => {
  return (
    <ItemsWrapper id="deployment-list">
      <Grid>
        {regions.map(region => {
          const locationClassList = ''

          return (
            <Item
              key={region.contentful_id}
              classes={locationClassList}
              region={region}
              initialDeployment={initialDeployment}
              resetInitialDeployment={resetInitialDeployment}
            />
          )
        })}
      </Grid>
    </ItemsWrapper>
  )
}

Items.propTypes = {
  regions: PropTypes.array,
  initialDeployment: PropTypes.string,
  resetInitialDeployment: PropTypes.func,
}

export default Items
