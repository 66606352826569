import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from '@Store'
import Container from '@components/Container'
import { ListWrapper } from './index.style'
import { Heading1 } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import Filters from '@components/Deployments/Filters'
import Map from '@components/Deployments/Map'
import Items from '../Items'
import { checkFilterMatch } from '@utils'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import HorizontalRule from '@components/HorizontalRule'

const List = ({
  yearTags,
  monthTags,
  locationTags,
  portTags,
  regions,
  location,
  initialDeployment,
  resetInitialDeployment,
}) => {
  const [filteredRegions, setFilteredRegions] = useState(regions)
  const [activeYearFilters, setActiveYearFilters] = useState([])
  const [activeMonthFilters, setActiveMonthFilters] = useState([])
  const [activeLocationFilters, setActiveLocationFilters] = useState([])
  const [activePortFilters, setActivePortFilters] = useState([])
  const listRef = useRef()
  const shouldFilterRef = useRef(true)

  const [store, dispatch] = useStore()

  const { smoothScroll, internalLocation } = store

  useEffect(() => {
    const matchingRegions = []

    regions.forEach(region => {
      if (!region.linkedDeployments) {
        return
      }

      const matchingResults = region.linkedDeployments.flatMap(deployment => {
        const yearFilterMatch =
          activeYearFilters.length === 0 ||
          checkFilterMatch(deployment.metadata.tags, activeYearFilters)

        const monthFilterMatch =
          activeMonthFilters.length === 0 ||
          checkFilterMatch(deployment.metadata.tags, activeMonthFilters)

        const locationFilterMatch =
          activeLocationFilters.length === 0 ||
          checkFilterMatch(deployment.metadata.tags, activeLocationFilters)

        const portFilterMatch =
          activePortFilters.length === 0 ||
          checkFilterMatch(deployment.metadata.tags, activePortFilters)

        return yearFilterMatch &&
          monthFilterMatch &&
          locationFilterMatch &&
          portFilterMatch
          ? [deployment]
          : []
      })

      if (matchingResults.length > 0) {
        matchingRegions.push({
          ...region,
          linkedDeployments: matchingResults,
        })
      }
    })

    setFilteredRegions(matchingRegions)
  }, [
    regions,
    activeMonthFilters,
    activeYearFilters,
    activeLocationFilters,
    activePortFilters,
  ])

  // Location param from url
  useEffect(() => {
    if (!shouldFilterRef.current) {
      return
    }

    const urlParams = new URLSearchParams(location?.search)
    const locationParam = urlParams.get('location')

    if (locationParam) {
      // This works differently to the rest because it was here before we
      // added the other filters.
      setActiveLocationFilters([`Deployment Location: ${locationParam}`])
    }

    shouldFilterRef.current = false
  }, [
    location,
    setActiveLocationFilters,
    setActiveMonthFilters,
    setActiveYearFilters,
    smoothScroll,
  ])

  // Location param from store
  useEffect(() => {
    if (internalLocation !== '') {
      setActiveLocationFilters([`Deployment Location: ${internalLocation}`])

      // Remove set location
      setTimeout(() => {
        dispatch({
          type: 'SET_INTERNAL_LOCATION',
          payload: '',
        })
      }, 100)
    }
  }, [internalLocation, dispatch])

  return (
    <ListWrapper ref={listRef} id="deployment-list-anchor">
      <Container>
        <AnimateSplitText>
          <Heading1>Browse our Deployment Resources</Heading1>
        </AnimateSplitText>
        <Spacer size={[28, 48]} />
        <HorizontalRule />
        <Spacer size={[28, 48]} />
        <Filters
          activeYears={activeYearFilters}
          setActiveYears={setActiveYearFilters}
          activeMonths={activeMonthFilters}
          setActiveMonths={setActiveMonthFilters}
          activeLocation={activeLocationFilters}
          setActiveLocation={setActiveLocationFilters}
          activePort={activePortFilters}
          setActivePort={setActivePortFilters}
          years={yearTags}
          months={monthTags}
          locations={locationTags}
          ports={portTags}
        />
        <Items
          regions={filteredRegions}
          initialDeployment={initialDeployment}
          resetInitialDeployment={resetInitialDeployment}
        />
        <Spacer size={[40, 80]} />
        <HorizontalRule />
        <Map
          regions={filteredRegions}
          tags={locationTags}
          activeFilter={activeLocationFilters}
          setActiveFilter={setActiveLocationFilters}
        />
      </Container>
    </ListWrapper>
  )
}

List.propTypes = {
  monthTags: PropTypes.array,
  yearTags: PropTypes.array,
  locationTags: PropTypes.array,
  portTags: PropTypes.array,
  regions: PropTypes.array,
  location: PropTypes.object,
  initialDeployment: PropTypes.string,
  resetInitialDeployment: PropTypes.func,
}

export default List
