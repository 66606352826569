import { colors } from '@styles/vars/colors.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { font } from '@styles/vars/font.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const FiltersWrapper = styled.div`
  position: relative;
  z-index: 10;
`

export const FiltersButtons = styled.ul`
  display: none;
  list-style-type: none;

  ${mq.desk} {
    display: flex;
  }
`

export const Filter = styled.li`
  margin-right: 1.8rem;
`

export const FiltersMultiSelect = styled.div`
  text-align: left;
  margin-bottom: 2.4rem;

  ${mq.desk} {
    margin-bottom: 0;
  }
`

export const FiltersMultiSelectTrigger = styled.button`
  appearance: none;
  background-color: ${colors.light};
  border-radius: 2.4rem;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 3.2rem;
  width: 100%;

  svg {
    transition: transform 0.4s ${cubicBezier.out};
  }

  ${props =>
    props.open &&
    `
    border-radius: 2.4rem 2.4rem 0 0;

    svg {
      transform: rotate(180deg);
    }
    `}
`

export const FiltersMultiSelectCount = styled.span`
  color: ${colors.blue};
  display: inline-flex;
  gap: 0.7rem;
  align-items: center;
`

export const FiltersMultiSelectOptionsWrap = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
`

export const FiltersMultiSelectOptions = styled.ul`
  background-color: ${colors.light};
  border-top: 0.1rem solid ${colors.grey};
  border-radius: 0 0 2.4rem 2.4rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px 0px;
  padding: 1.6rem 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  li {
    list-style-type: none;
  }

  ${props =>
    !props.open &&
    `
    display: none;
    `}
`

export const FiltersMultiSelectCheckBox = styled.span`
  background-color: ${colors.grey};
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const FiltersMultiSelectOption = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0.8rem 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${FiltersMultiSelectCheckBox} {
    transition: background-color 0.4s ${cubicBezier.out};

    svg {
      opacity: 0;
      transition: opacity 0.4s ${cubicBezier.out};
    }
  }

  ${props =>
    props.active &&
    `
    ${FiltersMultiSelectCheckBox} {
    background-color: ${colors.blue};

      svg {
        opacity: 1;
      }
    }
  `}
`

export const FiltersSelect = styled.div`
  display: inline-block;
  position: relative;

  ${mq.desk} {
    display: none;
  }

  svg {
    pointer-events: none;
    position: absolute;
    right: 2.2rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const FiltersSelectInput = styled.select`
  appearance: none;
  background-color: transparent;
  border: 0.1rem solid ${colors.blue};
  border-radius: 2.2rem;
  color: ${colors.dark};
  font-size: 1.6rem;
  font-weight: ${font.secondary.weight.medium};
  letter-spacing: 0.01em;
  padding: 0.9rem 4.4rem 0.9rem 2.6rem;
  white-space: nowrap;
`
